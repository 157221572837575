import { LeftCircleFilled, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Carousel, Col, Modal, Row, Select, Steps } from 'antd';
import * as React from 'react';
import { useHome } from '../../Hooks/useHome';
import { useProcedures } from '../../Hooks/useProcedures';
import { IModalViewerProps } from './IModalViewerProps';
import './ModalViewer.css';
const { Option } = Select;
const { Step } = Steps;

const ModalViewer: React.SFC<IModalViewerProps> = (props) => {

  const {
    selectedNewsLetter,
    setSelectedNewsLetter,
    goToSlide,
    ref,
    slideDotPosition,
    gotoPrevSlide,
    gotoNextSlide,
    setslideDotPosition
  } = useHome();

  const {
    imgProcess
  } = useProcedures();

  return (
    <div>
      <Modal
        title={props.contentType !== 'newsletter' ? props.title :
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              {props.title}
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11}></Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}> Seleccione:
              <Select
                style={{ width:'50%', paddingLeft:'5px'}}
                className='select'
                defaultValue="sep"
                onChange={(e) => setSelectedNewsLetter(e)}>
                <Option value="sep">Septiembre 2022</Option>
                <Option value="ago">Agosto 2022</Option>
                <Option value="jul">Julio 2022</Option>
                <Option value="jun">Junio 2022</Option>
                <Option value="may">Mayo 2022</Option>
                <Option value="abr">Abril 2022</Option>
                <Option value="mar">Marzo 2022</Option>
                <Option value="feb">Febrero 2022</Option>
                <Option value="ene">Enero 2022</Option>
                <Option value="dic">Diciembre 2021</Option>
                <Option value="nov">Noviembre 2021</Option>
              </Select>
            </Col>

          </Row>
        }
        visible={props.modalViewerVisible}
        onOk={() => props.setModalViewerVisible(false)}
        onCancel={() => props.setModalViewerVisible(false)}
        width={"70%"}
        style={{ top: 10 }}
        footer={[]}
      >
        {props.contentType == 'page' &&
          <iframe
            src={props.src}
            allowFullScreen
            width="100%"
            frameBorder={0}
            height={600}
          />
        }
        {props.contentType == 'image' &&
          <img
            src={props.src}
            width="100%"
          />
        }

        {props.contentType == 'docs' &&
          <iframe
            src={props.src}
            allowFullScreen
            width="100%"
            frameBorder={0}
            height={600}
          />}
        {props.contentType == 'newsletter' &&
          <iframe
            src={`https://qualitasfunds.blob.core.windows.net/$web/${selectedNewsLetter}_esp.html`}
            allowFullScreen
            width="100%"
            frameBorder={0}
            height={600}
          />}
        {props.contentType == 'process' &&
          <div>
            <Steps current={slideDotPosition} >
              {imgProcess.map((p, i) =>
                <Step onClick={() => goToSlide(i)} />
              )}
            </Steps>


            <Row>
              <Col span={3}>
                <div className='dot L_Dot' onClick={gotoPrevSlide}>
                <img  className='arrows'src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/leftIcon.jpg' />
                </div>
              </Col>
              <Col span={18}>

                <Carousel effect="fade" dots={false} ref={ref} beforeChange={(from, to) => setslideDotPosition(to)}>
                  {
                    imgProcess.map(p =>
                      <Row className='process'>

                        <img src={p.image} />
                    <div className='textProcess'>{p.title}</div>    
                      </Row>
                    )}
                </Carousel>
              </Col>
              <Col span={3}>
                <div onClick={gotoNextSlide} className='dot R_Dot'>
                  <img className='arrows'
                   src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/rightIcon.jpg' />                </div>
              </Col>
            </Row>

          </div>
        }

      </Modal>
    </div>
  );
};

export default ModalViewer;
