import '../Header/Header.css';
import { Col, Row } from 'antd';

const SuggestionBoxHeader = () => {
  return (
    <div>
      <Row className='contenedorHeader'>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <img className='logo' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/LogoQualitasFunds.png' />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}/>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Row className='paddingTop40'>
            <Col offset={18} xs={2} sm={2} md={2} lg={2} xl={2}>
              <a href='/'>
                <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-home-q.svg' />
              </a>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <a href='https://www.linkedin.com/company/qualitas-funds/'>
                <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-linkdln.svg' />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div >
  )
}

export default SuggestionBoxHeader
