import { Button, Col, Form, Input, Row } from 'antd';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { IEmailApprovalProps } from './IEmailApprovalProps';
import './EmailApproval.css';
import TextArea from 'antd/lib/input/TextArea';
import { useVacation } from '../../../Hooks/useVacation';
import { useContext, useEffect } from 'react';
import { appContext } from '../../../Context/appContext';
import moment from 'moment';



const EmailApproval: React.SFC<IEmailApprovalProps> = (props) => {

    const { GetVacationRequestById, vacationResponse, setVacationResponse,handleCancelVacationRequest,loading } = useVacation();
    const { userData } = useContext(appContext);

    useEffect(() => {
        GetVacationRequestById();
    }, []);

    return (
        <div>
            <Header />
            <div className='titleApp'>
                Gestión solicitud {vacationResponse?.type}
            </div><br />
            <Row>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                <Col xs={6} sm={6} md={3} lg={3} xl={3}>
                    Solicitante:
                </Col>
                <Col xs={16} sm={16} md={4} lg={4} xl={4} className='bold'>
                    {vacationResponse?.employeeName}
                </Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0}></Col>
                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
                <Col xs={6} sm={6} md={2} lg={2} xl={2}>
                    Estado:
                </Col>
                <Col xs={16} sm={16} md={5} lg={5} xl={5} className='bold'>
                    {vacationResponse?.status}
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
            </Row><br />

            <Row>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                <Col xs={6} sm={6} md={3} lg={3} xl={3}>
                    Fecha inicio:
                </Col>
                <Col xs={16} sm={16} md={4} lg={4} xl={4} className='bold'>
                    {moment(vacationResponse?.startDate).format("DD-MM-YYYY")}
                </Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0}></Col>
                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
                <Col xs={6} sm={6} md={2} lg={2} xl={2}>
                    Fecha fin:
                </Col>
                <Col xs={16} sm={16} md={5} lg={5} xl={5} className='bold'>
                    {moment(vacationResponse?.endDate).format("DD-MM-YYYY")}
                </Col>
                <Col xs={2} sm={2} md={4} lg={4} xl={4}></Col>
            </Row><br />

            <Row>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                <Col xs={6} sm={6} md={3} lg={3} xl={3}>
                    <Form.Item label="Observaciones">
                    </Form.Item>
                </Col>
                <Col xs={16} sm={16} md={13} lg={13} xl={13} className='bold'>
                    {vacationResponse?.employeeObservations}
                </Col>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            </Row>

            <Row>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                <Col xs={22} sm={22} md={3} lg={3} xl={3}>
                    <Form.Item label="Observaciones jefe">
                    </Form.Item>
                </Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0}></Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0}></Col>
                <Col xs={22} sm={22} md={23} lg={13} xl={13}>
                    <TextArea value={vacationResponse?.bossObservations} disabled={(vacationResponse?.status != "Pendiente") || (!userData.isBoss)} onChange={(e) => setVacationResponse((prevState: any) => ({
                        ...prevState,
                        ["bossObservations"]: e.target.value
                    }))} />
                </Col>
                <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            </Row><br />
            {(vacationResponse?.status == "Pendiente" && userData.isBoss) &&
                <Row>
                    <Col xs={1} sm={1} md={16} lg={16} xl={16}></Col>
                    <Col xs={11} sm={11} md={2} lg={2} xl={2}>
                        <Button className="boton" loading={loading} onClick={()=>handleCancelVacationRequest(vacationResponse,"Aprobado")}>✓ Aprobar</Button>
                    </Col>
                    <Col xs={11} sm={11} md={2} lg={2} xl={2}>
                        <Button className="botonRechazar" loading={loading}  onClick={()=>handleCancelVacationRequest(vacationResponse,"Rechazado")}>X Rechazar</Button>
                    </Col>
                    <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                </Row>
            }
            <br />
            <Footer />
        </div>
    )
}

export default EmailApproval;