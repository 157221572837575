import { useRef, useState } from "react";
import Applications from "../Components/Applications/Applications";

export const useHome = () => {


const [item, setitem] = useState('inicio');
const [modalViewerVisible, setModalViewerVisible] = useState<boolean>(false);
const [titleModalViewer, setTitleModalViewer] = useState<string>("");
const [srcModalViewer, setSrcModalViewer] =  useState<string>("");
const [contentTypeModal, setContentTypeModal] = useState<'image' | 'page' | 'video' | 'docs' | 'newsletter' | 'process'>('page');
const [selectedNewsLetter, setSelectedNewsLetter] = useState<string>('sep');
const [slideDotPosition, setslideDotPosition] = useState(0);

const ref:any = useRef();

  const goToSlide = (slide:number) => {
    ref.current.goTo(slide, true);
    setslideDotPosition(slide)
  };

  const gotoPrevSlide = () => {
      ref.current.prev();
  }

  const gotoNextSlide = () => {
    ref.current.next();
}


    const showItem = (value: string) => {
        setitem(value)
    }

    const onClicNewsletter = () => {
        window.open("https://qualitasfunds.blob.core.windows.net/$web/ene_esp.html", '_blank');
    }

    const openModalViewer = (title:string,src:string,contentType:'image' | 'page' | 'video'| 'docs' | 'newsletter'| 'process', ) =>
  
    {
        setTitleModalViewer(title);
        setSrcModalViewer(src);
        setContentTypeModal(contentType)
        setModalViewerVisible(true);
    }



    return {
        showItem,
        item,
        onClicNewsletter,
        modalViewerVisible, 
        setModalViewerVisible,
        openModalViewer,
        srcModalViewer,
        titleModalViewer,
        contentTypeModal,
        selectedNewsLetter, 
        setSelectedNewsLetter,
        goToSlide,
        slideDotPosition,
        ref,
        gotoPrevSlide,
        gotoNextSlide,
        setslideDotPosition
    }
}