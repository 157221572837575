import { Col, Drawer, Row, Tooltip } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { useHeader } from '../../../Hooks/useHeader';
import EditProfile from '../../EditProfile/EditProfile';
import MyApps from '../../MyApps/MyApps';
import { IuserProfileDrawerProps } from './IuserProfileDrawerProps';
import './userProfileDrawer.css';

const UserProfileDrawer: React.SFC<IuserProfileDrawerProps> = (props) => {

  const { userData, logOut, drawerVisible, setDrawerVisible } = useHeader();

  return (
    <div>
      <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-trespuntos.svg' onClick={() => setDrawerVisible(true)} />

      <Drawer
        title={<Row><Col span={12}>Mi perfil</Col><Col span={12} className='right itemMenu' onClick={logOut}>Sign Out</Col></Row>}
        placement="right" onClose={() => setDrawerVisible(false)} visible={drawerVisible}>

        <Row>
          <Col span={22} className='userName'>{`${userData.name} ${userData.lastName}`}</Col>
          <Tooltip title="Editar mi perfil">
            <Col span={2} className='userName'><EditProfile/></Col>
          </Tooltip>
          <Col span={24}>{userData.email}</Col>
          <Col span={24}>{userData.area}</Col>
        </Row>
        <br />
        <hr />
        <div className='userName'>Mis aplicaciones</div><br />
        <Row >
          <Col span={24}>
            <MyApps setDrawerVisible={setDrawerVisible} />
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default UserProfileDrawer;
