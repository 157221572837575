import { useEffect, useState } from "react";
import powerBiAPI from "../Api/api.powerBI";
import { Embed, models, Report } from 'powerbi-client';
import { powerBI } from "../Models/PowerBI.model";

export const usePowerBi = () => {

  const [report, setReport] = useState<Report>();
  const [PowerBIEmbedUrl, setPowerBIEmbedUrl] = useState("https://app.powerbi.com/reportEmbed");
  const [PowerBIToken, setPowerBIToken] = useState("");
  const [dataPowerBI, setdataPowerBI] = useState<powerBI>({
    id:0,
    title:"",
    description:"",
    embedId:"",
    application:"",
    active:true
  });

    useEffect(() => {
        obtenerTokenPowerBI();
        GetPowerBISettings('Intranet');
    }, []);

    const obtenerTokenPowerBI = () => {
        powerBiAPI.getBIToken().then((token: any) => {
          setPowerBIToken(token.AccessToken)
        })
      }

    const GetPowerBISettings = (app:string) => {
      powerBiAPI.GetPowerBISettings(app).then(res=>{
        setdataPowerBI(res);
      })
    }


    return{
        PowerBIEmbedUrl,
        PowerBIToken,
        report,
        setReport,
        dataPowerBI,
        setdataPowerBI,
        GetPowerBISettings
    }
}