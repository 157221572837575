import { useEffect, useState } from "react";
import { useHeader } from "./useHeader";
import '../Components/Procedures/Procedures.css';
import { useHome } from "./useHome";


export interface IProcess {
  process: string,
  subProcess: string,
  doc: string,
  url: string
}

export const useProcedures = () => {

  const [backGroudImg, setBackGroudImg] = useState<string>("backGroudImgProcess");
  const [selectedProcess, setSelectedProcess] = useState<string>("");
  const [subProcess, setSubProcess] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState("");
  const [processDocumentList, setProcessDocumentList] = useState<IProcess[]>([
    {
      process: "Investments",
      subProcess: "",
      doc: "",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "General",
      doc: "GP",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "General",
      doc: "Investment Fund",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "General",
      doc: "Investment Cia",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "General",
      doc: "Investment Valuation",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "General",
      doc: "Fund Transfer",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "Investment Cash-Flow",
      doc: "Capital Call",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "Investment Cash-Flow",
      doc: "Distribution Notice",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "Investment Cias",
      doc: "Cia",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "Investment Cias",
      doc: "Operation (Buy or Sell)",
      url: ""
    },
    {
      process: "Portfolio Management",
      subProcess: "Investment Cias",
      doc: "Finantial",
      url: ""
    },
    {
      process: "Investors Management",
      subProcess: "GP",
      doc: "GP",
      url: ""
    },
    {
      process: "Investors Management",
      subProcess: "Capital Call",
      doc: "Capital Call",
      url: ""
    },
    {
      process: "Investors Management",
      subProcess: "Distribution Notice",
      doc: "Distribution Notice",
      url: ""
    },
    {
      process: "Investors Management",
      subProcess: "Fund Transfer",
      doc: "Fund Transfer",
      url: ""
    },
    {
      process: "Compliance & Legal",
      subProcess: "Compliance",
      doc: "Compliance",
      url: ""
    },
    {
      process: "Compliance & Legal",
      subProcess: "Gestora",
      doc: "Gestora",
      url: ""
    },
    {
      process: "Compliance & Legal",
      subProcess: "Vehículos",
      doc: "Vehículos",
      url: ""
    },
    {
      process: "Compliance & Legal",
      subProcess: "Inversores",
      doc: "Inversores",
      url: ""
    },
    {
      process: "Compliance & Legal",
      subProcess: "Otros",
      doc: "Otros",
      url: ""
    },
    {
      process: "ESG Principles",
      subProcess: "Company Level",
      doc: "Company Level",
      url: ""
    }
    ,
    {
      process: "ESG Principles",
      subProcess: "Investments",
      doc: "Investments",
      url: ""
    }
    ,
    {
      process: "ESG Principles",
      subProcess: "Reporting",
      doc: "Reporting",
      url: ""
    }

  ]);
  const [isProcessSelected, setisProcessSelected] = useState<boolean>(false);

  const {
    modalViewerVisible,
    setModalViewerVisible,
    srcModalViewer,
    titleModalViewer,
    contentTypeModal,
    openModalViewer,
  } = useHome();


  const processDocumentListTree = [
    {
      title: <span>Investments</span>,
      key: 'backGroudImgProcess1-Investments-Investments',
      children: [

      ],
    },
    {
      title:  <span>Portfolio Management</span>,
      key: 'backGroudImgProcess2-Portfolio Management-Portfolio Management',
      children: [
        { title: 'General', key: 'backGroudImgProcess2-Portfolio Management-General'},
        { title: 'Investment Cias', key: 'backGroudImgProcess2-Portfolio Management-Investment Cias'},
        { title: 'Investment Cash-Flow', key: 'backGroudImgProcess2-Portfolio Management-Investment Cash Flow'},
      ],
    },
    {
      title: 'Investors Management',
      key: 'backGroudImgProcess3-Investors Management-Investors Management',
      children: [
        { title: 'GP', key: 'backGroudImgProcess3-Investors Management-GP'},
        { title: 'Capital Call', key: 'backGroudImgProcess3-Investors Management-Capital Call'},
        { title: 'Distribution Notice', key: 'backGroudImgProcess3-Investors Management-Distribution Notice'},
        { title: 'Edit Fund Transfer', key: 'backGroudImgProcess3-Investors Management-Edit Fund Transfer'},
      ],
    },
    {
      title: 'Compliance & Legal',
      key: 'backGroudImgProcess4-Compliance & Legal-Compliance & Legal',
      children: [
        { title: 'Compliance', key: 'backGroudImgProcess4-Compliance & Legal-Compliance'},
        { title: 'Gestora', key: 'backGroudImgProcess4-Compliance & Legal-Gestora'},
        { title: 'Vehículos', key: 'backGroudImgProcess4-Compliance & Legal-Vehículos'},
        { title: 'Inversores', key: 'backGroudImgProcess4-Compliance & Legal-Inversores'},
        { title: 'Otros', key: 'backGroudImgProcess4-Compliance & Legal-Otros'},
      ],
    },
    {
      title: 'ESG Principles',
      key: 'backGroudImgProcess5-ESG Principles-ESG Principles',
      children: [
        { title: 'Company Level', key: 'backGroudImgProcess5-ESG Principles-Company Level' },
        { title: 'Investments ', key: 'backGroudImgProcess5-ESG Principles-Investments '},
        { title: 'Reporting', key: 'backGroudImgProcess5-ESG Principles-Reporting' },
      ],
    },
  ];

  const imgProcess = [
    {
      title: "En backoffice, seleccionar Investors y luego Capital Calls",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC1.png"
    },

    {
      title: "Seleccionar el vehículo al que se quiere realizar la solicitud de desembolso",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC3.png"
    },
    {
      title: "",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC4.png"
    },
    {
      title: "La fecha seleccionada corresponderá con la fecha de vencimiento de la solicitud de desembolso",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC4.1.png"
    },
    {
      title: "Posibilidad de agrupar a inversores específicos mediante la selección de uno o varios cierres",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC4.2.png"
    },
    {
      title: "Formulario de registro del valor liquidativo al que se va a realizar la solicitud de desembolso",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC4.3.png"
    },
    {
      title: "Formulario para especificar el o los conceptos por los que se va a realizar la solicitud de desembolso, así como la cantidad total de la misma.",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC4.4.png"
    },
    {
      title: "",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC5.png"
    },
    {
      title: "Descarga del master de inversores para comprobar que la solicitud de desembolso ha quedado guardada en la base de datos ",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC6.png"
    },
    {
      title: "Descarga de la solicitud de desembolso y última comprobación antes de iniciar el proceso de comunicación ",
      image: "https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/CapitalCall/CC8.png"
    },
  ]

  const { openURL } = useHeader();

  const HandleChangeSelectedProcess = (backgroundImage: string, process: string) => {
    setBackGroudImg(backgroundImage);
    setSelectedProcess(process);
    setSelectedKey(`${backgroundImage}-${process}-${process}`);
  }

  const handleClickProcess = ( process: string) => {
    setisProcessSelected(true);
    setSelectedProcess(process);
  }

  const HandleSelectProcess = (subProces: string) => {
    if(subProcess == "Capital Call")
    {
      openModalViewer("Proceso Capital Call",'','process')
      setSubProcess("");
      setSelectedProcess("");
      setBackGroudImg("backGroudImgProcess");
    }
    else{
      setisProcessSelected(false);
      setSubProcess(subProces);
    }

  }

  const onSelect = (keys: any, info: any) => {
    var backgroundImage = keys[0].split("-")[0];
    var process = keys[0].split("-")[1];
    var subProcess = keys[0].split("-")[2];
    HandleSelectProcess(subProcess);
    setSelectedKey(`${backgroundImage}-${process}-${process}`)
    HandleChangeSelectedProcess(backgroundImage,process)
  };

  return {
    backGroudImg,
    setBackGroudImg,
    selectedProcess,
    HandleChangeSelectedProcess,
    processDocumentList,
    subProcess,
    HandleSelectProcess,
    processDocumentListTree,
    onSelect,
    selectedKey,
    imgProcess,
    modalViewerVisible,
    setModalViewerVisible,
    srcModalViewer,
    titleModalViewer,
    contentTypeModal,
    isProcessSelected,
    handleClickProcess
  }
}