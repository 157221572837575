import { Button, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import * as React from 'react';
import { useSearch } from '../../Hooks/useSearch';
import { ISearchProps } from './ISearchProps';
import './Search.css';

const SearchModal: React.SFC<ISearchProps> = (props) => {

  const {onSearchDocument,onCloseSearchModal,searchText,setSearchText} = useSearch();

  return (
    <Modal
          visible={props.SearchModalVisible}
          title='Buscar documentos'
          onOk={()=>onCloseSearchModal(props.SetSearchModalVisible)}
          onCancel={()=>onCloseSearchModal(props.SetSearchModalVisible)}
          footer={[
            <Button key="submit" type="primary" onClick={()=>onCloseSearchModal(props.SetSearchModalVisible)}>
              Cerrar
            </Button>
          ]}
        >
         <Search placeholder="Buscador..." value={searchText} onChange={(e)=>setSearchText(e.target.value)} onSearch={onSearchDocument} enterButton />
        </Modal>
  );
};

export default SearchModal;
