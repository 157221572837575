import { Col, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import '../Components/Header/Header.css';
import MyApps from '../Components/MyApps/MyApps';
import { appContext } from '../Context/appContext';
import { Utils } from '../Utils';
export const useHeader = () => {

    const { userData } = useContext(appContext);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [SearchModalVisible, setSearchModalVisible] = useState<boolean>(false);

    const logOut = () => {
        Utils.delete_cookie("intranetToken");
        window.location.href = "https://qualitasfunds.com/";
    }

    const openURL = (url:string) => {
        window.open(url,'_blank');
    }

    const openSearchModal = () => {
        setSearchModalVisible(true);
    }

    return {
        userData,
        openURL,
        logOut,
        drawerVisible, 
        setDrawerVisible,
        setSearchModalVisible,
        SearchModalVisible,
        openSearchModal
    }
}