import { Button, Col, DatePicker, Form, Input, Modal, Row, Upload } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, Export, FilterRow, GroupPanel, HeaderFilter, Sorting } from 'devextreme-react/data-grid';
import * as React from 'react';
import { useKnowledge } from '../../Hooks/useKnowledge';
import { IKnowledgeProps } from './IKnowledgeProps';
import './Knowledge.css';
import { UploadOutlined } from '@ant-design/icons';
import ModalViewer from '../ModalViewer/ModalViewer';
import { useHome } from '../../Hooks/useHome';

const Knowledge: React.SFC<IKnowledgeProps> = (props) => {

    const { closeModalKnowledge, showModalKnowledge, isModalVisibleKnowledge, goToLink
    } = useKnowledge();

    const {
        showItem,
        item,
        onClicNewsletter,
        modalViewerVisible,
        setModalViewerVisible,
        openModalViewer,
        srcModalViewer,
        titleModalViewer,
        contentTypeModal
    } = useHome();

    const dataSource = [
        {
            Date: '30-12-2021',
            Theme: '2021.12-Intelectual Capital',
            URL: 'https://qualitasfunds.blob.core.windows.net/intranet-assets/Knowledge_Docs/2021.12- Intelectual Capital.pdf',
            Description: "En este documento encontrarás la evolución de la actividad de PE durante el periodo, la evolución del fundraising en Europa, que los LPs consideran el PE como el principal activo para crear impacto social y medioambien y como los Fondos de Fondos se han consolidado como el mejor activo alternativo durante las últimas dos décadas."
        },
        {
            Date: '30-09-2021',
            Theme: '2021.09-Intelectual Capital',
            URL: 'https://qualitasfunds.blob.core.windows.net/intranet-assets/Knowledge_Docs/2021.09- Intelectual Capital_vf.pdf',
            Description: "En este documento encontrarás la evolución de la actividad de M&A en el PE europeo durante el periodo, la evolución del fundraising en Europa y la tasa de mortandad de las emporesas en función de su tamaño."
        },
        {
            Date: '30-06-2021',
            Theme: '2021.06-Intelectual Capital',
            URL: 'https://qualitasfunds.blob.core.windows.net/intranet-assets/Knowledge_Docs/2021.06-Intellectual Capital_vshared.pdf',
            Description: "En este documento encontrarás la evolución de la actividad de M&A en el PE europeo durante el periodo, el impacto que la pandemia ha tenido en el fundraising en Europa,y la diferencia de la actividad entre las regiones económicas de norte y el sur de Europa."
        },
        {
            Date: '31-03-2021',
            Theme: '2021.03-Intelectual Capital',
            URL: 'https://qualitasfunds.blob.core.windows.net/intranet-assets/Knowledge_Docs/2021.03-Intelectual Capital.pdf',
            Description: "En este documento encontrarás la evolución de la actividad de M&A en el PE europeo durante el periodo, la popularización de las coinversiones y como han ido ganando peso en las carteras de loss inversores durante los últimos años y la rápida expansión e integración del ESG en los activos financieros."
        },
        {
            Date: '23-12-2020',
            Theme: '2020.12-Intelectual Capital',
            URL: 'https://qualitasfunds.blob.core.windows.net/intranet-assets/Knowledge_Docs/2020.12-Intelectual Capital_vf.pdf',
            Description: "En este documento encontrarás la recuperación de la actividad de M&A en Europa tras la segunda ola de la pandemia, el crecimiento del apetito de los grandes inversores por el PE, el comportamiento de las coinversiones en periodos post-crisis y que pese a la pandemia el ESG sigue siendo clave para los inversores a la hora de tomar decisiones de inversión."
        }
    ]

    return (
        <div>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='miga'>Inicio / Knowledge center </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='titleApp'>
                      Documentos para consulta
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>

            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22} style={{ paddingBottom: '20px' }}>
                    <DataGrid
                        dataSource={dataSource}
                        showBorders={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        rowAlternationEnabled={true}
                    >
                        <HeaderFilter visible={true} />
                        <Sorting mode="multiple" />
                        <GroupPanel visible={true} />
                        <Export enabled={true} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Column dataField="Date" width={90} caption="Fecha" allowEditing={false} />
                        <Column dataField="Theme" width={190} caption="Temática" allowEditing={false} />
                        <Column dataField="Description" caption="Descripción" allowEditing={false} />
                        <Column dataField="URL" width={100} caption="Ver" type="buttons" alignment="center"
                            buttons={[{
                                icon: 'pdffile',
                                hint: 'Ver informe',
                                // onClick: (x: any) => { goToLink(x.row.data.Link) }
                                onClick: (x: any) => openModalViewer(
                                    x.row.data.Theme,
                                    x.row.data.URL,
                                    'docs')
                            },
                            ]} />

                    </DataGrid>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                </Col>
            </Row>

            <Modal
                title="Knowledge"
                visible={isModalVisibleKnowledge}
                onOk={closeModalKnowledge}
                onCancel={closeModalKnowledge}
                footer={[
                    <Button key="submit" className="boton" onClick={closeModalKnowledge}>
                        Agregar
                    </Button>,
                ]}
            >
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label="Fecha">
                        </Form.Item>
                    </Col>
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <DatePicker />
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item label="Temática">
                        </Form.Item>
                    </Col>
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <Input placeholder="Temática" />
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            name="upload"
                            label="Informe"
                            valuePropName="fileList"
                        >
                        </Form.Item>
                    </Col>
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <Upload name="logo" action="/upload.do" listType="picture">
                            <Button icon={<UploadOutlined />}>Select file</Button>
                        </Upload>
                    </Col>
                </Row>
            </Modal>

            <ModalViewer
                title={titleModalViewer}
                setModalViewerVisible={setModalViewerVisible}
                modalViewerVisible={modalViewerVisible}
                src={srcModalViewer}
                contentType={contentTypeModal}
            />

        </div>
    );
};

export default Knowledge;


