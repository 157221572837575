import React, { useEffect, useState } from 'react';
import { IPowerBIProps } from './IPowerBIProps';
import './PowerBI.css';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models, Report } from 'powerbi-client';
import { usePowerBi } from '../../Hooks/usePowerBI';
import { Col, Row } from 'antd';

const PowerBI: React.SFC<IPowerBIProps> = (props) => {


  const {
    PowerBIToken,
    PowerBIEmbedUrl,
    setReport,
    dataPowerBI,
  } = usePowerBi();

  return (

    <div>
      {console.log(dataPowerBI)}
      {(dataPowerBI.active && dataPowerBI.embedId) ?
        <Row>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={22} sm={22} md={22} lg={22} xl={22}>
            <div className='titlePowerBI'>{dataPowerBI.title}</div>
            <div className='descriptionPowerBI'>{dataPowerBI.description}</div><br />

            <PowerBIEmbed
              embedConfig={{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: dataPowerBI.embedId,
                embedUrl: PowerBIEmbedUrl,
                accessToken: PowerBIToken,
                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    },
                  },
                  navContentPaneEnabled: false,
                  background: models.BackgroundType.Transparent,
                }
              }}

              eventHandlers={
                new Map([
                  ['loaded', function () { console.log('Report loaded'); }],
                  ['rendered', function () { console.log('Report rendered'); }],
                  ['error', function (event: any) { console.log(event.detail); }]
                ])
              }

              cssClassName={"report-style-class"}

              getEmbeddedComponent={(embedObject: Embed) => {
                console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                setReport(embedObject as Report);
              }}
            />
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
        </Row>
        :
        <img width={'100%'} style={{ padding: '10px' }} src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Inicio.jpg' />
      }
    </div>
  );
};

export default PowerBI;
