import * as React from 'react';
import { IApplicationsProps } from './IApplicationsProps';
import './Applications.css';
import { Button, Col, Row } from 'antd';
import { useApplications } from '../../Hooks/useApplications';
import InternalApps from './InternalApps/InternalApps';


const Applications: React.SFC<IApplicationsProps> = (props) => {

  const { onClicExternal, onClicInternal, getAppsEmployee } = useApplications();


  return (
    <div>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
          <div className='miga'>Inicio / Aplicaciones </div>
          <div className='titleApp'>
            Aplicaciones
          </div><br />
          <InternalApps />
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>

    </div>
  );
};

export default Applications;
