import axios from 'axios';
import { powerBI } from '../Models/PowerBI.model';

const WebAapiURL = process.env.REACT_APP_WebAapiURL;

const powerBiAPI = {
    getBIToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`https://services.qualitasfunds.com/api/PowerBIToken`)
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    GetPowerBISettings(Application:string): Promise<powerBI> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Masters/GetPowerBISettings?Application=${Application}`)
                .then(json => resolve(json.data))
                .catch(err => reject(err));
        });
    }
}

export default powerBiAPI;