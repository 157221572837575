import { useState } from "react";

export const useKnowledge = () => {



    const [isModalVisibleKnowledge, setIsModalVisibleKnowledge] = useState(false);

    const showModalKnowledge = () => {
        setIsModalVisibleKnowledge(true);
    };

    const closeModalKnowledge = () => {
        setIsModalVisibleKnowledge(false);
    };

    const goToLink = (d: string) => {
        window.open(d, '_blank')
    }

    return {
        closeModalKnowledge,
        showModalKnowledge,
        isModalVisibleKnowledge,
        goToLink
    }
}