import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import { IPuntoTecProps } from './IPuntoTecProps';
import './PuntoTec.css';

const PuntoTec: React.SFC<IPuntoTecProps> = (props) => {


  return (
    <div>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
          <div className='miga'>Inicio / Punto Tec </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
      <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='titleApp'>
                        Punto Tec
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row><br/>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="cardP">
          <div className='titledirectory'>Soporte</div>
          <div className='email'><PhoneFilled className='icons' /> 91 533 00 24 Ext.1</div>
          <div className='email'> <MailFilled className='icons' />
            <a href="mailto:socorro@puntotec.com"> socorro@puntotec.com</a>
          </div>
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="cardP">
          <div className='titledirectory'>Ivan</div>
          <div className='email'><PhoneFilled className='icons' /> 633 440 350 / 915330024 Ext: 147 </div>
          <div className='email'> <MailFilled className='icons' />
            <a href="mailto:socorro@puntotec.com"> ifernandez@puntotec.com</a>
          </div>
        </Col><br/>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="cardP">
          <div className='titledirectory'>Victor</div>
          <div className='email'><PhoneFilled className='icons' /> 663 710 766 / 915330024 Ext: 150 </div>
          <div className='email'> <MailFilled className='icons' />
            <a href="mailto:socorro@puntotec.com"> vgarcia@puntotec.com</a>
          </div>
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="cardP">
          <div className='titledirectory'>María</div>
          <div className='email'><PhoneFilled className='icons' /> 610 528 804 / 915330024 Ext: 171 </div>
          <div className='email'> <MailFilled className='icons' />
            <a href="mailto:socorro@puntotec.com"> mepeldegui@puntotec.com</a>
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>

    </div>
  );
};

export default PuntoTec;
