import * as React from 'react';
import { IVacationContainerProps } from './IVacationContainerProps';
import './VacationContainer.css';
import DaysSummary from '../DaysSummary/DaysSummary';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import MyRequests from '../MyRequests/MyRequests';
import { useVacation } from '../../../Hooks/useVacation';
import Calendar from '../Calendar/Calendar';
import { useContext, useEffect } from 'react';
import { appContext } from '../../../Context/appContext';

const VacationContainer: React.SFC<IVacationContainerProps> = (props) => {

    const { userData } = useContext(appContext);


    const { handleOkRequest, 
        showModalRequest, 
        handleCancelRequest, 
        onClicRequests, 
        onClicReturn, 
        request, 
        isModalVisible,
        loading,
        onClicCalendar,
        calendarArea,
        handleUpdateVacationsRequest, 
        saveVacationRequest,
        vacationTotals,
        calendar,
        componentType,
        setComponentType,
        GetVacationRequestList,
        GetVacationTeamRequestList,
        GetVacationsTotals
     } = useVacation();

     useEffect(() => {
        GetVacationRequestList();
        if (userData.isBoss) {
            GetVacationTeamRequestList();
        }
        GetVacationsTotals(props.type);
    }, []);

    return (
        <div >
            <div className='title'> 
                {props.type}
            </div>
            <Row gutter={[16, 16]} >
                <Col xs={12} sm={12} md={4} lg={4} xl={4} >
                    {!request ? 
                        <div className="boton" onClick={() => onClicRequests()}>Solicitudes</div>
                        :
                        <div className="boton" onClick={() => onClicReturn()}>← Volver</div>
                    }
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='accessBoton'>
                {!calendar ? 
                        <div className="boton" onClick={() => onClicCalendar('user')}>Mi calendario</div>
                        :
                        <div className="boton" onClick={() => onClicReturn()}>← Volver</div>
                }

                </Col>
                <Col xs={0} sm={0} md={8} lg={8} xl={8} className='accessBoton'>
                </Col>
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className='accessBoton'>
                    <div className="boton" onClick={() => onClicCalendar('team')}>Calendario área</div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="boton" onClick={showModalRequest}>+ Nuevo</div>
                </Col>
            </Row>

            {(!request && !calendar && !calendarArea) &&
                <div>
                    <div >
                        <img className='vacations' src="https://qualitasfunds.blob.core.windows.net/images/fondosLogin/3.jpg"></img>
                    </div>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <DaysSummary days={vacationTotals.total} title='Total'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <DaysSummary days={vacationTotals.approved} title='Aprobados'/>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <DaysSummary days={vacationTotals.available} title='Disponibles'/>
                        </Col>
                    </Row>
                </div>
            }
            <br/>
            {request && <MyRequests type={props.type}/>}
            {calendar && <Calendar type='user'/>}
            {calendarArea && <Calendar type='team'/>}

            <Modal

                title="Nueva solicitud"
                visible={isModalVisible}
                onOk={handleOkRequest}
                onCancel={handleCancelRequest}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={()=>saveVacationRequest(props.type)}>
                        Enviar
                    </Button>,
                ]}
            >
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Form.Item label="Fecha inicial">
                            <DatePicker format={['DD/MM/YYYY', 'DD/MM/YY']} onChange={(e)=>handleUpdateVacationsRequest(e,"startDate")}/>
                        </Form.Item>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Form.Item label="Fecha final">
                            <DatePicker format={['DD/MM/YYYY', 'DD/MM/YY']} onChange={(e)=>handleUpdateVacationsRequest(e,"endDate")}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        Observaciones:
                        <TextArea onChange={(e)=>handleUpdateVacationsRequest(e.target.value,"employeeObservations")}/>
                    </Col>
                </Row>
            </Modal>

        </div >

    );
};

export default VacationContainer;