import * as React from 'react';
import { IHeaderProps } from './IHeaderProps';
import './Header.css';
import { Avatar, Col, Popover, Row } from 'antd';
import { useHeader } from '../../Hooks/useHeader';
import { HomeOutlined, LinkedinOutlined, SearchOutlined } from '@ant-design/icons';
import UserProfileDrawer from './userProfileDrawer/UserProfileDrawer';
import SearchModal from '../Search/Search';

const Header: React.SFC<IHeaderProps> = (props) => {

  const {
    userData,
    openURL,
    drawerVisible, 
    setDrawerVisible,
    setSearchModalVisible,
    SearchModalVisible,
    openSearchModal
  } = useHeader();

  return (
    <div>
      
      <Row className='contenedorHeader'>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <img className='logo' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/LogoQualitasFunds.png' onClick={()=>openURL('https://qualitasfunds.com/')}/>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>

        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Row className='paddingTop40'>
            <Col xs={14} sm={14} md={14} lg={14} xl={14} className='top15 floatR'>
              Hola, {userData.name}
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <Avatar src={userData.avatar} size={30} style={{marginTop:10}}>{userData.name.charAt(0)}</Avatar>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <UserProfileDrawer/>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-home-q.svg' onClick={()=>openURL('/')}/>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-linkdln.svg' onClick={()=>openURL('https://www.linkedin.com/company/qualitas-funds/')}/>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <img className='icon' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-buscador.svg' onClick={openSearchModal}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <SearchModal SetSearchModalVisible={setSearchModalVisible} SearchModalVisible={SearchModalVisible}/>
    </div >
  );
};

export default Header;
