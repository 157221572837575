import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'antd/dist/antd.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Home from './Components/Home/Home';
import 'devextreme/dist/css/dx.light.css';

import Login from './Components/Login/Login';
import { useAppContext } from './Hooks/useAppContext';
import { appContext } from './Context/appContext';
import InternalApps from './Components/Applications/InternalApps/InternalApps';
import EmailApproval from './Components/Vacation/EmailApproval/EmailApproval';
import SuggestionBox from './Components/SuggestionBox/SuggestionBox';

function App() {

  const {userData} = useAppContext();

  return (
    <>      
    <BrowserRouter>
      <Routes>
        <Route path="/Suggestions" element={<SuggestionBox />} />
      </Routes>
    </BrowserRouter>
    <appContext.Provider value={{userData}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/InternalApps" element={<InternalApps/>} />
          <Route path='/vacation/approval' element={<EmailApproval/>}/>
        </Routes>
      </BrowserRouter>
    </appContext.Provider>
    </>
  );
}

export default App;
