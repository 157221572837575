import axios from 'axios';
import { Employee, EmployeeDirectoryDTO, responsabilityAreas } from '../Models/Employee.model';
import { EmployeePermissions } from '../Models/EmployeePermissions.model.';
import { userData } from '../Models/userData';
import { getVacationRequest, VacataionListResponse, VacationRequest, vacationTotals } from '../Models/Vacation.model';
const WebAapiURL = process.env.REACT_APP_WebAapiURL;
const EmployeeAPI = {
    getEmployee(email: string): Promise<userData> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetEmployeeByEmail?email=${email}`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    getEmployeeModules(employeeId: number): Promise<EmployeePermissions[]> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetEmployeeModules?EmployeeId=${employeeId}`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    PostVacationRequest(data: VacationRequest): Promise<boolean> {
        return new Promise((resolve, reject) => {
            axios.post(`${WebAapiURL}v1/Employees/PostVacationRequest`,data, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    GetVacationRequestList(data: getVacationRequest): Promise<VacataionListResponse[]> {
        return new Promise((resolve, reject) => {
            axios.post(`${WebAapiURL}v1/Employees/GetVacationRequestList`,data, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    GetVacationsTotals(IdEmployee: number,Type:string): Promise<vacationTotals> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetVacationsTotals?IdEmployee=${IdEmployee}&Type=${Type}`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    GetVacationRequestById(RequestId: any): Promise<VacataionListResponse> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetVacationRequestById?RequestId=${RequestId}`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },


    GetResponsabilityAreas(): Promise<responsabilityAreas[]> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetResponsabilityAreas`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },

    
    GetEmployeeDirectory(): Promise<EmployeeDirectoryDTO[]> {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Employees/GetEmployeeDirectory`, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    UploadImage(formData:any):Promise<string> {
        return new Promise((resolve, reject) => {
                axios.post(`${WebAapiURL}v1/BlobStorage/UploadImageGallery`,formData,
                { 
                    headers: {"Authorization" : `Bearer ${localStorage.adminAppToken}`},
                    timeout: 1000 * 600
                })
                    .then(json =>resolve(json.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
        });
    },
    PostSuggestion(EmployeeId:number,Suggestion:string):Promise<string> {
        return new Promise((resolve, reject) => {
                axios.get(`${WebAapiURL}v1/Employees/PostSuggestion?EmployeeId=${EmployeeId}&Suggestion=${Suggestion}`,
                { 
                    headers: {"Authorization" : `Bearer ${localStorage.adminAppToken}`},
                    timeout: 1000 * 600
                })
                    .then(json =>resolve(json.data))
                    .catch((error) => {
                        // Error 😨
                        reject(error)
                    });
        });
    },
    PostUserData(data: userData): Promise<boolean> {
        return new Promise((resolve, reject) => {
            axios.post(`${WebAapiURL}v1/Employees/PostUserData`,data, { headers: {"Authorization" : `Bearer ${localStorage.intranetToken}`} })
                .then(response => resolve(response.data))
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        reject(error.response.data)
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        });
    },
    
}

export default EmployeeAPI;