import * as React from 'react';
import { IEditProfileProps } from './IEditProfileProps';
import './EditProfile.css';
import { Button, Col, Input, Modal, Row, Upload } from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppContext } from '../../Hooks/useAppContext';


const EditProfile: React.SFC<IEditProfileProps> = (props) => {

  const {
    userData,
    updateEmployeeData,
    showModalEditUserData, 
    propsFile,
    setShowModalEditUserData,
    handleChange
  } = useAppContext();

  return (
    <div>
      <EditOutlined onClick={()=>setShowModalEditUserData(true)}/>
      <Modal title={
      <Row>
        <Col span={10}>Área: {userData.area}</Col>
        <Col span={14}>{userData.email}</Col>
      </Row>} 
      width={800}
      visible={showModalEditUserData} 
      onOk={updateEmployeeData} 
      onCancel={()=>setShowModalEditUserData(false)}
      >
        <Row gutter={[16,16]}>
          <Col span={3}>Nombre:</Col>
          <Col span={8}><Input value={userData.name} onChange={handleChange} name='name'/></Col>
          <Col span={3}>Apellido:</Col>
          <Col span={8}><Input value={userData.lastName} onChange={handleChange} name='lastName'/></Col>
        </Row>
        <br/>
        <Row gutter={[16,16]}>
          <Col span={3}>Teléfono:</Col>
          <Col span={8}><Input value={userData.phone} onChange={handleChange} name='phone'/></Col>
          <Col span={3}>Imágen:</Col>
          <Col span={8}>
            <Upload name="logo" listType="picture" {...propsFile}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload></Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditProfile;
