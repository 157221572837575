import { notification } from "antd";
import axios from "axios";
import { useState } from "react";
const WebAapiURL = process.env.REACT_APP_WebAapiURL;
const ApiKey = process.env.REACT_APP_XApiKey;
const SuggestionEmail = process.env.REACT_APP_SuggestionEmail;

export const useSuggestions = () => {

    const [suggestion, setSuggestion] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    const sendSuggestion = async (suggestion: string) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('Body', suggestion);
        formData.append('ToEmail', `${SuggestionEmail}`);
        formData.append('Subject', "New Suggestion from Qualitas Funds Employee");

        await axios({
            method: 'post',
            headers:{
                XApiKey: `${ApiKey}`
            },
            url: `${WebAapiURL}Masters/Email/SendEmailAsync`,
            data: formData
          })
          .then(res => {
              notification.success({message:"Suggestion has been sent"});
              setSuggestion('')
          })
          .catch(error => {
              notification.error({
                  message: 'An error ocurred, please try again later',
                  description: `${error}`,
              });
          })
          .finally(()=> {
            setLoading(false)
          })
	};
    return{
        suggestion,
        sendSuggestion,
        setSuggestion,
        loading
    }
}