import { Col, Popover, Row, Tooltip, List, Typography, Divider, Select, Collapse } from 'antd';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import * as React from 'react';
import { useState } from 'react';
import { useHeader } from '../../Hooks/useHeader';
import { useHome } from '../../Hooks/useHome';
import { useProcedures } from '../../Hooks/useProcedures';
import ModalViewer from '../ModalViewer/ModalViewer';
import { IProceduresProps } from './IProceduresProps';
import './Procedures.css';
const { Option } = Select;

const Procedures: React.SFC<IProceduresProps> = (props) => {

  const { Panel } = Collapse;

  const {

    backGroudImg,
    selectedProcess,
    HandleChangeSelectedProcess,
    processDocumentList,
    subProcess,
    HandleSelectProcess,
    processDocumentListTree,
    onSelect,
    selectedKey,
    modalViewerVisible,
    setModalViewerVisible,
    srcModalViewer,
    titleModalViewer,
    contentTypeModal,
    isProcessSelected,
    handleClickProcess
  } = useProcedures();

  return (
    <div>

      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
          <div className='miga'>Inicio / Mapa procesos </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
      <Divider orientation="right">{selectedProcess}</Divider>

      <Row >
        <Col xs={12} sm={12} md={1} lg={1} xl={1}>     </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
          <div  onClick={() => handleClickProcess('Investments')  } >
            <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/investments.jpg' className='procedure' />
          </div>
          <div  onClick={() => handleClickProcess('Compliance & Legal')} >
            <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/compliance.jpg' className='procedure'/>
          </div>
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={2}>     </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
          <div   onClick={() => handleClickProcess('Portfolio Management')}>
            <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/portfolio.jpg' className='procedure' />
          </div>
          <div  onClick={() => handleClickProcess('Investors Management')} >
            <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/investors.jpg'  className='procedure'/>
          </div>
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={2}>     </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div  onClick={() => handleClickProcess('ESG Principles')}>
            <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/Process/esg.jpg' className='procedureB' />
          </div>
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={2}>     </Col>
      </Row>

      {console.log(isProcessSelected, selectedProcess)}
      {!isProcessSelected ?
        <Row style={{ padding: '10px' }}>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={23} sm={23} md={23} lg={23} xl={23}>
            <List
              header={<div className='tableDocs'>
                <b>{subProcess}</b>
              </div>
              }
              bordered
              dataSource={processDocumentList.filter(x => x.subProcess == subProcess)}
              renderItem={item => (
                <List.Item>
                  {item.doc}
                </List.Item>
              )}
              style={{ minHeight: 300 }}
            />
          </Col>
        </Row>
        :
        <Row style={{ padding: '10px' }}>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
          <Col xs={23} sm={23} md={23} lg={23} xl={23}>
            <List
              header={<b>{selectedProcess}</b>}
              bordered
              dataSource={processDocumentList.filter(x => x.process == selectedProcess).filter((v, i, a) => a.findIndex(t => (t.subProcess === v.subProcess)) === i)}
              renderItem={item => (
                <List.Item>
                  {item.subProcess}
                </List.Item>
              )}
              style={{ minHeight: 300 }}
            />
          </Col>
        </Row>
      }

 




      {/*   <ModalViewer
        title={titleModalViewer}
        setModalViewerVisible={setModalViewerVisible}
        modalViewerVisible={modalViewerVisible}
        src={srcModalViewer}
        contentType={contentTypeModal}
      /> */}

    </div >
  );
};

export default Procedures;

