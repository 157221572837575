import { createContext } from "react";
import { userData } from "../Models/userData";


export const appContext = createContext<appContext>({
    userData: {
        idEmployee: 0,
        name: "",
        lastName: "",
        email: "",
        area: "",
        isBoss: false,
        phone:"", 
        avatar:""
    }
})

type appContext = {
    userData: userData
}
