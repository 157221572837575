import { useState } from "react";

export const useSearch = () => {

    const [searchText, setSearchText] = useState<string>("");

    const onSearchDocument = (e:any) => {
        window.open(`https://qualitasfunds.sharepoint.com/search/results.aspx?k=${e}`);
    }

    const onCloseSearchModal = (SetSearchModalVisible:Function) => {
        setSearchText("");
        SetSearchModalVisible(false);
    }

    return{
        searchText,
        onSearchDocument,
        onCloseSearchModal,
        setSearchText
    }
}