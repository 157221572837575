import * as React from 'react';
import { IDocsProps } from './IDocsProps';
import './Docs.css';
import { Card, Col, Divider, Row, Select } from 'antd';
import { useDocsAccess } from '../../Hooks/useDocsAccess';
const { Option } = Select;

const Docs: React.SFC<IDocsProps> = (props) => {

    const {
        openFolder,
        selectedOpenType,
        setSelectedOpenType
    } = useDocsAccess();

    return (
        <div>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='miga'>Inicio / Documentos </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='titleApp' style={{ paddingBottom: '20px' }}>
                       Documentos
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginLeft:'10px'}}>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4} >
                    <a href="https://qualitasfunds.sharepoint.com/sites/inversiones" target={'_blank'}>
                        <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/iconInversiones.jpg' />
                    </a>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                    <a href="https://qualitasfunds.sharepoint.com/sites/fundraising" target={'_blank'}>
                        <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/iconFundraising.jpg' />
                    </a>
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} xl={1}></Col>
                <Col xs={1} sm={1} md={0} lg={0} xl={0}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4} >
                    <a href="https://qualitasfunds.sharepoint.com/sites/reporting" target={'_blank'}>
                        <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/iconReporting.jpg' /></a>
                </Col>
                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
            </Row>
            <br/>
            <Row gutter={[16, 16]} style={{marginLeft:'10px'}}>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                    <a href="https://qualitasfunds.sharepoint.com/sites/operaciones" target={'_blank'}>
                        <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/iconOperaciones.jpg' />
                    </a>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={4} >
                    <a href="https://qualitasfunds.sharepoint.com/sites/otrasareas" target={'_blank'}>
                        <img src='https://qualitasfunds.blob.core.windows.net/intranet-assets/iconOtras.jpg' />
                    </a>
                </Col>
            </Row>
            <br/>
                {/*          <Col xs={20} sm={20} md={7} lg={7} xl={7} className='textDocs'>
                    <div onClick={openFolder}>Otras áreas</div>
                </Col> */}
            
        </div>
    );
};

export default Docs;
