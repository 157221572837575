import { appContext } from '../Context/appContext';
import { useContext, useEffect, useState } from 'react';
import EmployeeAPI from '../Api/api.employees';
import { EmployeePermissions } from '../Models/EmployeePermissions.model.';


export const useApplications = () => {

    const { userData } = useContext(appContext);
    const [AppsEmployee, setAppsEmployee] = useState<EmployeePermissions[]>([]);

    useEffect(() => {
        if(userData.idEmployee != 0)
        {
            getAppsEmployee(userData.idEmployee);
        }
      }, [userData]);

    const onClicExternal = () => {
        window.open("https://qualitasfunds.com", '_blank');
    }

    const onClicInternal = () => {
        window.open("/InternalApps", '_blank')
    }

    const getAppsEmployee = (id: number) => {
        EmployeeAPI.getEmployeeModules(id).then(res => {
            setAppsEmployee(res)
        })
    }

    const goToApp = (url:string) => {
            window.open(url, '_blank');
        }

    return { 
        onClicExternal,
        onClicInternal,
        getAppsEmployee,
        AppsEmployee,
        goToApp
    }

}