import { useState } from "react";
import { useHeader } from "./useHeader";


export const useDocsAccess = () => {

    const [selectedOpenType, setSelectedOpenType] = useState<string>("Online");
    const{openURL} = useHeader();

    const openFolder = () => {
        if(selectedOpenType == "Online")
        {
            openURL("https://qualitasfunds.sharepoint.com/sites/otrasareas");
        }
        else
        {
            
            window.location.href = 'file:///C:/Users/gusta/QUALITAS EQUITY FUNDS, S.G.E.I.C, S.A/Otras áreas - Documentos'
            window.open('file:///C:/Users/gusta/QUALITAS EQUITY FUNDS, S.G.E.I.C, S.A/Otras áreas - Documentos')
        }
    }


    return{
        openFolder,
        selectedOpenType, 
        setSelectedOpenType
    }
}