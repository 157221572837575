import * as React from 'react';
import { IDirectoryProps } from './IDirectoryProps';
import './Directory.css';
import { Avatar, Badge, Button, Card, Col, Divider, Input, Row, Select, Space, Spin, Tag } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, Export, FilterRow, GroupPanel, Sorting } from 'devextreme-react/data-grid';
import { DeleteFilled, DeleteOutlined, MailFilled, MailOutlined, PhoneFilled, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useDirectory } from '../../Hooks/useDirectory';
const { Search } = Input;
const { Option } = Select;
const Directory: React.SFC<IDirectoryProps> = (props) => {

    const { EmployeeDirectoryFiltered,
        onSearch,
        loading,
        handleSelectedUsers,
        selectedUsers,
        sendEmail,
        responsabilityAreas,
        cleanTag
    } = useDirectory();

    return (

        <div>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='miga'>Inicio / Directorio </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row>
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <div className='titleApp'>
                        Siempre en contacto
                    </div>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            </Row><br />
            <Row>
                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                </Col>
                <Col xs={15} sm={15} md={8} lg={8} xl={8}>
                    <Space direction="vertical">
                        <Search placeholder="Escriba el nombre" style={{ width: 300 }} onSearch={(e) => onSearch(e, "name")} onChange={(e) => onSearch(e.target.value, "name")} />
                    </Space>
                </Col>

                <Col xs={4} sm={4} md={5} lg={5} xl={5}>
                    <span style={{ paddingRight: '10px' }}>Áreas</span>
                    <Select style={{ width: 120 }} onChange={(e) => onSearch(e, "tag")}  >
                        {responsabilityAreas.map(a =>
                            <Option value={a.name}>{a.name}</Option>
                        )}
                    </Select>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3} >
                    <Button onClick={cleanTag}> Quitar filtros <DeleteFilled className='buttonFilter' /></Button>
                </Col>

                <Col xs={20} sm={20} md={3} lg={3} xl={3} style={{ paddingLeft: '15px'}}>
                    <Button onClick={sendEmail}>   Enviar email <MailFilled className='buttonFilter' /></Button>
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} xl={1}>
                </Col>
            </Row><br />
            <Spin spinning={loading} tip="Cargando...">
                <Row gutter={[16, 16]} >
                    {EmployeeDirectoryFiltered.map((x, i) =>
                        <>
                            <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            <Col xs={20} sm={20} md={10} lg={10} xl={10} className='card'>
                                <Row>
                                    <Col xs={22} sm={22} md={6} lg={6} xl={6}>
                                        <Avatar shape="square" className='avatar' size={70}
                                            src={x.avatar}
                                            icon={!x.avatar ? <UserOutlined /> : null}
                                        />
                                    </Col>
                                    <Col xs={22} sm={22} md={16} lg={16} xl={16}>
                                        <br />
                                        <div className='titledirectory'>{x.name} {x.lastName}</div>
                                        <div className='titledirectory'>{x.area}</div>
                                        <div className='email'><PhoneFilled className='icons' /> {x.phone}</div>

                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <Checkbox style={{ paddingTop: '15px' }} onChange={(e) => handleSelectedUsers(e.target.checked, x.email)} />
                                    </Col>
                                </Row>

                                <div className='email'> <MailFilled className='icons' />
                                    <a href={`mailto:${x.email}`} style={{ paddingLeft: '5px' }}> {x.email}</a>
                                </div>
                                <hr />
                                {x.responsabilities.map(r =>
                                    <Tag className='tagEmployee'>{r.responsabilityName}</Tag>
                                )}
                            </Col>
                            {i % 2 == 0 ?
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                :
                                <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                            }
                        </>
                    )}
                </Row><br />
            </Spin>
        </div>
    );
};

export default Directory;
