import * as React from 'react';
import { IHomeProps } from './IHomeProps';
import './Home.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Applications from '../Applications/Applications';
import Docs from '../Docs/Docs';
import Procedures from '../Procedures/Procedures';
import Knowledge from '../Knowledge/Knowledge';
import { Button, Card, Col, Row, Skeleton } from 'antd';
import './Home.css';
import { useHome } from '../../Hooks/useHome';
import Sider from 'antd/lib/layout/Sider';
import Directory from '../Directory/Directory';
import ModalViewer from '../ModalViewer/ModalViewer';
import PowerBI from '../PowerBI/PowerBI';
import { MessageOutlined } from '@ant-design/icons';
import SuggestionBox from '../SuggestionBox/SuggestionBox';
import PuntoTec from '../PuntoTec/PuntoTec';

const Home: React.SFC<IHomeProps> = (props) => {

  const {
    showItem,
    item,
    onClicNewsletter,
    modalViewerVisible,
    setModalViewerVisible,
    openModalViewer,
    srcModalViewer,
    titleModalViewer,
    contentTypeModal
  } = useHome();


  return (
    <div>
      <Header />
      <Row>
        <Sider breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken: any) => {
            console.log(broken);
          }}
          onCollapse={(collapsed: any, type: any) => {
            console.log(collapsed, type);
          }}
          style={{ background: '#efeff1' }}
        >
          <Col xs={24} sm={24} md={4} lg={4} xl={4} >
            <div className='menu' onClick={() => showItem('inicio')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-home.svg' />
              </div>
              <div >INICIO</div>
            </div>
            <div className='menu' onClick={() => showItem('applications')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-app.svg' />
              </div>
              <div >APLICACIONES</div>
            </div>
            <div className='menu' onClick={() => showItem('docs')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-docs.svg' />
              </div>
              <div >DOCUMENTOS</div>
            </div>
            <div className='menu' onClick={() => showItem('procedures')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-procesos.svg' />
              </div>
              <div >MAPA PROCESOS</div>
            </div>
            <div className='menu' onClick={() => showItem('knowledge')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-report.svg' />
              </div>
              <div >KNOWLEDGE CENTER</div>
            </div>
            <div className='menu' onClick={() => showItem('directorio')}>
              <div className='menuItemIcon'>
                <img className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/icon-agenda.svg' />
              </div>
              <div >DIRECTORIO</div>
            </div>
            <div className='menu' onClick={() => window.location.href = `/Suggestions`}>
              <div className='menuItemIcon'>
                <img style={{width:'50px'}} className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/buzon.png' />
              </div>
              <div >SUGERENCIAS</div>
            </div>
            <div className='menu' onClick={() => showItem('PuntoTec')}>
              <div className='menuItemIcon'>
                <img style={{width:'45px'}} className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/punto.png' />
              </div>
              <div >PUNTOTEC</div>
            </div>
<br/>


          </Col>
        </Sider>
        <Col xs={24} sm={24} md={20} lg={20} xl={20} >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='banner'>
              <div className="video-container">

                <video autoPlay={true} muted loop>
                  <source src="https://qualitasfunds.blob.core.windows.net/intranet-assets/Videos/fondoBanner.mp4" type="video/mp4" />
                </video>

                <div className="caption">
                  <div>Bienvenido a nuestro punto de encuentro</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              {item == 'inicio' && <PowerBI />}
              {item == 'applications' && <Applications />}
              {item == 'docs' && <Docs />}
              {item == 'procedures' && <Procedures />}
              {item == 'knowledge' && <Knowledge />}
              {item == 'directorio' && <Directory />}
              {item == 'PuntoTec' && <PuntoTec />}

            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card
                title="Newsletter Qualitas Funds"
                hoverable style={{ width: '100%' }}
                onClick={() => openModalViewer(
                  "Newsletter Qualitas Funds",
                  '',
                  'newsletter')}>
                <img style={{ width: '95%' }} src='https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20220113T094358950-Cabecero Q1.jpg' />
              </Card>
              <br />
              <Card
                title="Estado actual distribuciones y llamadas a capital"
                hoverable style={{ width: '100%' }}
                onClick={() => openModalViewer(
                  "Estado actual distribuciones y llamadas a capital",
                  'https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20220116T204929123-Flujos ES.jpg',
                  'image')}
              >
                <img style={{ width: '100%' }} src='https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20220116T204929123-Flujos ES.jpg' />
              </Card>
              <br />

            </Col>
          </Row>
        </Col>
      </Row>
      <ModalViewer
        title={titleModalViewer}
        setModalViewerVisible={setModalViewerVisible}
        modalViewerVisible={modalViewerVisible}
        src={srcModalViewer}
        contentType={contentTypeModal}
      />
      <Footer />
    </div>
  );
};

export default Home;
