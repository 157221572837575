import { Button, Calendar, Col, Row } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, ColumnFixing, Export, FilterRow, GroupPanel, Sorting } from 'devextreme-react/data-grid';
import { useVacation } from '../../../Hooks/useVacation';
import { IMyRequestsProps } from './IMyRequestsProps';
import './MyRequests.css';
import { Tabs } from 'antd';
import { useContext, useEffect } from 'react';
import { appContext } from '../../../Context/appContext';

const { TabPane } = Tabs;

const MyRequests: React.SFC<IMyRequestsProps> = (props) => {

    const { 
        vacationRequestList, 
        vacationTeamRequestList,
        cellRenderUpdateStatus,
        GetVacationRequestList,
        GetVacationTeamRequestList,
    } = useVacation();

    const { userData } = useContext(appContext);

    useEffect(() => {
       GetVacationRequestList();
       GetVacationTeamRequestList();
    }, []);

    return (
        <div className='contenedor'>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Mis solicitudes" key="1">
                    <div style={{ fontSize: '35px', fontWeight: 'bold', textAlign: 'center', color: '#485f73' }} >Mis solicitudes</div>
                    <DataGrid
                        dataSource={vacationRequestList.filter(x=>x.type == props.type)}
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                    >
                        <Sorting mode="multiple" />
                        <GroupPanel visible={true} />
                        <Export enabled={true} />
                        <ColumnFixing enabled={true} />
                        <FilterRow visible={true} />
                        <Column dataField="startDate" caption="Fecha inicio" dataType="date" format="dd/MM/yyyy" allowEditing={false} />
                        <Column dataField="endDate" caption="Fecha fin" dataType="date" format="dd/MM/yyyy" />
                        <Column dataField="days" caption="Días" allowEditing={false} width={50} />
                        <Column dataField="bossObservations" caption="Observaciones" allowEditing={false} />
                        <Column dataField="status" caption="Estado" allowEditing={false} />
                        <Column cellRender={(row)=>cellRenderUpdateStatus(row.data,"Employee")} width={35} alignment='center' allowEditing={false} />
                    </DataGrid>
                </TabPane>
                {userData.isBoss &&
                    <TabPane tab="Solicitudes de mi equipo" key="2">
                        <div style={{ fontSize: '35px', fontWeight: 'bold', textAlign: 'center', color: '#485f73' }} >Solicitudes de mi equipo</div>
                        <DataGrid
                            dataSource={vacationTeamRequestList}
                            showBorders={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                        >
                            <Sorting mode="multiple" />
                            <GroupPanel visible={true} />
                            <Export enabled={true} />
                            <ColumnFixing enabled={true} />
                            <FilterRow visible={true} />
                            <Column dataField="employeeName" caption="Nombre" allowEditing={false} />
                            <Column dataField="type" caption="Tipo" allowEditing={false} />
                            <Column dataField="startDate" caption="Fecha inicio" dataType="date" format="dd/MM/yyyy" allowEditing={false} />
                            <Column dataField="endDate" caption="Fecha fin" dataType="date" format="dd/MM/yyyy" />
                            <Column dataField="days" caption="Días"  allowEditing={false} width={50} />
                            <Column dataField="employeeObservations" caption="Observaciones solicitante"  allowEditing={false} />
                            <Column dataField="status" caption="Estado"  allowEditing={false} />
                            <Column cellRender={(row)=>cellRenderUpdateStatus(row.data,"Boss")} alignment='center' allowEditing={false} />
                        </DataGrid>
                    </TabPane>
                }
            </Tabs>

        </div>
    );
};

export default MyRequests;