import { Button, Col, Input, message, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import LoginAPI from '../../Api/api.login';
import { Auth } from '../../Models/Auth.model';
import { ILoginProps } from './ILoginProps';
import { UserOutlined } from '@ant-design/icons';
import './Login.css';
import { Utils } from '../../Utils';

const { confirm } = Modal;

const Login: React.SFC<ILoginProps> = (props: any) => {


  const [authData, setAuthData] = useState<Auth>({
    usuario: "",
    password: ""
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [correo, setCorreo] = useState("");
  const [imgLogin, setImgLogin] = useState("");

  
  const getImage = () => {  let number = Math.floor((Math.random() * 16) + 1);
    setImgLogin(`https://qualitasfunds.blob.core.windows.net/images/fondosLogin/${number}.jpg`);
}

useEffect(() => {
  getImage();
}, []);

  const login = () => {
    setLoading(true);
    LoginAPI.login(authData).then((res: any) => {
      let userData = Utils.parseJwt(res.data.token);
      console.log(userData)
      Utils.crearCookie("intranetToken", res.data.token, userData.exp);
      localStorage.intranetToken = res.data.token;
      window.location.href = "/";
      setLoading(false);
    })
      .catch((err:any) => {
        message.error('usuario o contraseña invalidos');
        setLoading(false);
      });
  }

  const handleChangeState = (e: any) => {
    const { value, name } = e.target;
    setAuthData((prevState:any) => ({
      ...prevState,
      [name]: value
    }))
  }

  const generate_token = (length: number) => {
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
        var j: any = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

const recuperarPass_ = () => {
    if (correo !== "") {
        setLoading(true)
        LoginAPI.SaveToken(correo,generate_token(20)).then((respuesta: any) => {
          if (respuesta) {
              message.success(`Se enviará un email a ${correo} para restaurar su contraseña`)
              setLoading(false);
              setModalVisible(false);
          }
      })
      .catch((err:any) =>{
          message.error("Se ha producido un error, por favor intente de nuevo")
          setLoading(false);
      });
    }
    else {
        message.warning("Indica cual es tu correo electrónico");
    }
}

  return (
    <div>
      <div className={`login}`}>
        <div className='componentTitle'>Intranet</div>
         <Row>
           <Col xs={1} sm={4} md={4} lg={4} xl={4}></Col>
           <Col xs={20} sm={16} md={16} lg={16} xl={16} className={"border"}>
             <Row>
               <Col span={12}>
                 <img src={imgLogin} className={"imgLogin"} />
               </Col>
               <Col span={1}></Col>
               <Col span={10} style={{textAlign:'center'}}>
                
                 <Row>
                   <Col span={24} style={{textAlign:'center',margin:20}}>
                     <img src='https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20210928T164816216-LogoQualitasFunds.png' />
                   </Col>
                 </Row>
                  <br />
                 <Row>
                 Usuario:
                <Input
                  placeholder="usuario"
                  name='usuario'
                  value={authData.usuario}
                  onChange={(e) => handleChangeState(e)}
                  className="input" />
                 </Row>
                 <br />
                 <Row>
                 Password:
                <Input.Password
                  placeholder="Password"
                  name='password'
                  value={authData.password}
                  onChange={(e) => handleChangeState(e)}
                />
                 </Row>
                 <br />
                 <Row>
                 <Button
          className="botonLogin"
          type="primary"
          onClick={login}
          loading={loading}
        >Login</Button>
        <div className="forgotPass" onClick={() => setModalVisible(true)}>
                  ¿ Olvidaste tu contraseña ?
                </div>
 
                 </Row>
                 <br /><br />
                  <div className='suggestionBoxItem'>
                      <button onClick={()=> window.location.href = "/Suggestions"}>
                        Buzón de Sugerencias 
                        <img alt="buzon" style={{width:'50px'}} className='img' src='https://qualitasfunds.blob.core.windows.net/intranet-assets/icons/buzon.png' />
                      </button>
                  </div>
               </Col>
             </Row>
           </Col>
           <Col xs={1} sm={4} md={4} lg={4} xl={4}></Col>
         </Row>
         <br /><br /><br />
     </div>
     
      <Modal
        style={{ top: 100 }}
        visible={modalVisible}
        onOk={recuperarPass_}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" loading={loading} onClick={() => setModalVisible(false)}>
            Cancelar
          </Button>,
          <Button loading={loading} key="submit" type="primary" onClick={recuperarPass_}>
            ok
          </Button>,
        ]}
      >
        Escriba su email:
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          style={{ height: 40 }}
          placeholder="Correo"
          value={correo}
          type="email"
          onChange={(e) => setCorreo(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Login;
