import { message, notification } from "antd";
import { useEffect, useState } from "react";
import EmployeeAPI from "../Api/api.employees";
import { userData } from "../Models/userData";
import { Utils } from "../Utils";

export const useAppContext = () => {

    const [showModalEditUserData, setShowModalEditUserData] = useState<boolean>(false);
    const [fileList, setFileList]: any = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userData, setUserData] = useState<userData>({
        idEmployee: 0,
        name: "",
        lastName: "",
        email: "",
        area: "",
        isBoss: false,
        phone: "",
        avatar: ""
    });

    useEffect(() => {
        if (window.location.pathname !== '/Login') {
            getToken();
        }
    }, []);


    const getToken = () => {
        if(window.location.pathname !== '/Suggestions'){
            Utils.comprobarCookie("intranetToken");
            let user = Utils.parseJwt(localStorage.intranetToken);
            getEmployeeData(user.email);
        }
    }

    const getEmployeeData = (email: string) => {
        EmployeeAPI.getEmployee(email).then(res => {
            setUserData(res);
        })
    }

    const uploadImage = () => {
        const formData = new FormData();
        fileList.map((x: any) => {
            formData.append('Attachments', x);
        })

        formData.append("strContainerName", "intranet-assets");
        formData.append('folderName', "userImages");
        return EmployeeAPI.UploadImage(formData).then(res => {
            setFileList([]);
            return res;
        })
    }

    const updateEmployeeData = async () => {
        setLoading(true);
        if (fileList.length > 0) {
            var resp = await uploadImage();
            console.log(resp)
            userData.avatar = resp;
        }
        EmployeeAPI.PostUserData(userData).then(res => {
            notification.success({ message: "Los datos fueron actualizados" });
            getEmployeeData(userData.email);
            setLoading(false);
            setShowModalEditUserData(false);
            
        })

    }

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setUserData((prevState:any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const propsFile = {
        onRemove: (file: any) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file: any) => {
            setFileList([...fileList, file])
            return false;
        },
        fileList,
        showUploadList: { showDownloadIcon: false }
    };


    return {
        userData,
        updateEmployeeData,
        showModalEditUserData,
        setShowModalEditUserData,
        propsFile,
        loading,
        handleChange
    }
}