import { Col, Modal, notification, Popconfirm, Row, Tooltip } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import EmployeeAPI from "../Api/api.employees";
import { appContext } from "../Context/appContext";
import { CalendarEvent, getVacationRequest, VacataionListResponse, VacationRequest, vacationTotals } from "../Models/Vacation.model";
import { CloseCircleOutlined, CheckCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import { Utils } from "../Utils";

const { confirm } = Modal;

export const useVacation = () => {

    const { userData } = useContext(appContext);
    const [vacationTotals, setVacationTotals] = useState<vacationTotals>({
        total: 0,
        approved: 0,
        available: 0
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleVacation, setisModalVisibleVacation] = useState(false);
    const [request, setrequest] = useState(false);
    const [vacationRequestList, setVacationRequestList] = useState<VacataionListResponse[]>([]);
    const [vacationResponse, setVacationResponse] = useState<VacataionListResponse>();
    const [vacationTeamRequestList, setVacationTeamRequestList] = useState<VacataionListResponse[]>([]);
    const [calendar, setcalendar] = useState(false);
    const [calendarArea, setcalendarArea] = useState(false);
    const [vacationsRequest, setVacationsRequest] = useState<VacationRequest>({
        idRequest: 0,
        idEmployee: 0,
        startDate: "",
        endDate: "",
        requestedDays: 0,
        type: "",
        employeeObservations: "",
        bossObservations: "",
        status: "Pendiente",
    });
    const [calendarListEvents, setCalendarListEvents] = useState<CalendarEvent[]>([]);
    const [calendarLIstEventsArea, setCalendarLIstEventsArea] = useState<CalendarEvent[]>([]);
    const [componentType, setComponentType] = useState<string>('Vacaciones');    

    const GetVacationsTotals = (type:string) => {
        EmployeeAPI.GetVacationsTotals(userData.idEmployee, type).then(res => {
            setVacationTotals(res);
        })
    }

    const showModalRequest = () => {
        setIsModalVisible(true);
    };

    const handleOkRequest = () => {
        setIsModalVisible(false);
    };

    const handleCancelRequest = () => {
        setIsModalVisible(false);
    };

    const onClicRequests = () => {
        setrequest(true);
        setcalendar(false);
        setcalendarArea(false);
    }

    const onClicCalendar = (type:'user'| 'team') => {
        setcalendar(false);
        setcalendarArea(false);
        setrequest(false);
        type == 'user' ? setcalendar(true) : setcalendarArea(true);
    }

    const onClicReturn = () => {
        setrequest(false);
        setcalendar(false);
        setcalendarArea(false);
    }

    const handleUpdateVacationsRequest = (_value: any, field: string) => {
        //CalendarEvent
        let value: any;
        if (field == "employeeObservations") {
            value = _value;
        }
        else {
            value = moment(_value).format("YYYY-MM-DD")
        }
        setVacationsRequest(prevState => ({
            ...prevState,
            [field]: value
        }));
    }

    const GetVacationRequestList = () => {
        let data: getVacationRequest = {
            employeeId: userData.idEmployee
        }
        EmployeeAPI.GetVacationRequestList(data).then(res => {
            setVacationRequestList(res);
            let arrayCalendarEvents:any=  res.filter(x=>x.status != 'Cancelado' && x.status != 'Rechazado').map(x=>{
                let p = x.startDate.split("T")[0]
                let q = x.endDate.split("T")[0]
                let el:CalendarEvent = {
                        title: x.employeeName,
                        start: new Date(p),
                        end: new Date(q),
                        allDay: true
                }

                return el;
            })
            setCalendarListEvents(arrayCalendarEvents);
        })
    }

    const GetVacationTeamRequestList = () => {
        let data: getVacationRequest = {
            bossId: userData.idEmployee
        }
        EmployeeAPI.GetVacationRequestList(data).then(res => {
            setVacationTeamRequestList(res);
            let arrayCalendarEvents:any=  res.map(x=>{
                let p = x.startDate.split("T")[0]
                let q = x.endDate.split("T")[0]
                let el:CalendarEvent = {
                        title: x.employeeName,
                        start: new Date(p),
                        end: new Date(q),
                        allDay: true
                }

                return el;
            })
            setCalendarLIstEventsArea(arrayCalendarEvents);
        })
    }

    const GetVacationRequestById = () => {
        let params = Utils.getQueryString()
        if(params.RequestId)
        {
            EmployeeAPI.GetVacationRequestById(params.RequestId).then(res=>{
                setVacationResponse(res);
            })
        }
        
    }

    const saveVacationRequest = (type:string) => {
        setLoading(true);
        vacationsRequest.idEmployee = userData.idEmployee;
        vacationsRequest.type = type;
        EmployeeAPI.PostVacationRequest(vacationsRequest).then(res => {
            notification.success({ message: 'La solicitud fue enviada!!' });
            setLoading(false);
        })
            .catch(err => {
                console.error(err)
                setLoading(false);
            });
    }

    const showModalVacation = (compType:string) => {
        setComponentType(compType);
        setisModalVisibleVacation(true);
    };

    const handleCloseModalVacation = () => {
        setisModalVisibleVacation(false);
    };

    const handleCancelVacationRequest = (req: VacataionListResponse, status: "Aprobado" | "Cancelado" | "Rechazado") => {
        setLoading(true);
        let data: VacationRequest = {
            idRequest: req.idRequest,
            idEmployee: req.idEmployee,
            startDate: req.startDate,
            endDate: req.endDate,
            requestedDays: req.days,
            type: req.type,
            employeeObservations: req.employeeObservations,
            bossObservations: req.bossObservations,
            status: status,
            created_At: req.created_At,
            idApprover: req.idApprover
        }
        EmployeeAPI.PostVacationRequest(data).then(res => {
            notification.success({ message: `El estado de la solicitud es: ${status}` });
            GetVacationRequestList();
            GetVacationTeamRequestList();
            GetVacationsTotals(req.type);
            GetVacationRequestById();
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    function showConfirm(req: VacataionListResponse, status: "Aprobado" | "Cancelado" | "Rechazado") {
        confirm({
          title: `Esta solicitud cambiará a estado: ${status}`,
          icon: <ExclamationCircleOutlined />,
          content: <div>Comentarios: <TextArea onChange={(e)=>{req.bossObservations = e.target.value}}/></div>,
          onOk() {
            handleCancelVacationRequest(req,status);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    const cellRenderUpdateStatus = (row: VacataionListResponse, userType: "Boss" | "Employee") => {
        let startDate = moment(row.startDate);
        let currentDate = moment(new Date);
        if (startDate > currentDate && userType == "Employee" && row.status != "Cancelado" && row.status != "Rechazado") {
            return <div>
                <Tooltip title="Cancelar Solicitud" placement="bottom">
                    <Popconfirm title="Cancelar esta solicitud?" onConfirm={() => handleCancelVacationRequest(row,"Cancelado")}>
                        <CloseCircleOutlined style={{ color: "red", cursor: "pointer" }} />
                    </Popconfirm>
                </Tooltip>
            </div>
        }

        if (startDate > currentDate && userType == "Boss" && row.status != "Cancelado" && row.status != "Rechazado") {
            return <Row>
                {row.status == "Aprobado" ?
                    <Col span={12}>
                        <Tooltip title="Cancelar Solicitud" placement="bottom">
                            <CloseCircleOutlined style={{ color: "red", cursor: "pointer" }}  onClick={() => showConfirm(row,"Cancelado")}/>
                        </Tooltip>
                    </Col>
                    :
                    <>
                        <Col span={12}>
                            <Tooltip title="Rechazar Solicitud" placement="bottom">
                                <CloseCircleOutlined style={{ color: "red", cursor: "pointer" }} onClick={() => showConfirm(row,"Rechazado")}/>
                            </Tooltip>
                        </Col>
                        <Col span={12}>
                            <Tooltip title="Aprobar Solicitud" placement="bottom">
                                <CheckCircleOutlined style={{ color: "green", cursor: "pointer" }} onClick={() => showConfirm(row,"Aprobado")}/>
                            </Tooltip>
                        </Col>
                    </>
                }

            </Row>
        }
    }


    return {
        handleOkRequest,
        showModalRequest,
        handleCancelRequest,
        onClicRequests,
        onClicReturn,
        request,
        isModalVisible,
        vacationsRequest,
        loading,
        handleUpdateVacationsRequest,
        saveVacationRequest,
        GetVacationRequestList,
        vacationRequestList,
        vacationTotals,
        showModalVacation,
        handleCloseModalVacation,
        isModalVisibleVacation,
        onClicCalendar,
        calendarArea,
        calendar,
        vacationTeamRequestList,
        cellRenderUpdateStatus,
        calendarLIstEventsArea,
        calendarListEvents,
        componentType,
        setComponentType,
        GetVacationRequestById,
        vacationResponse,
        setVacationResponse,
        handleCancelVacationRequest,
        GetVacationTeamRequestList,
        GetVacationsTotals
    }
}