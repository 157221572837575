import { userInfo } from "os";
import { useEffect, useState } from "react";
import { json } from "stream/consumers";
import EmployeeAPI from "../Api/api.employees";
import { Employee, EmployeeDirectoryDTO, responsabilityAreas } from "../Models/Employee.model";

export const useDirectory = () => {

    const [EmployeeDirectory, setEmployeeDirectory] = useState<EmployeeDirectoryDTO[]>([]);
    const [EmployeeDirectoryFiltered, setEmployeeDirectoryFiltered] = useState<EmployeeDirectoryDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [responsabilityAreas, setresponsabilityAreas] = useState<responsabilityAreas[]>([]);

    useEffect(() => {
        GetEmployeeDirectory();
        GetResponsabilityAreas();
    }, []); 

    const GetEmployeeDirectory = () => {
        setLoading(true);
        EmployeeAPI.GetEmployeeDirectory().then(res => {
            setEmployeeDirectoryFiltered(res);
            setEmployeeDirectory(res);
            setLoading(false);
        })
    }

    const GetResponsabilityAreas = () => {
        setLoading(true);
        EmployeeAPI.GetResponsabilityAreas().then(res => {
            setresponsabilityAreas(res);
        })
    }

    const onSearch = (name: string, type: string) => {
        let arrayDataFiltered: EmployeeDirectoryDTO[] = [];
        if (type == "name") {
            let data = EmployeeDirectory.filter(function (item: EmployeeDirectoryDTO) {
                if (item.name.toLowerCase().includes(name.toLowerCase())) {
                    arrayDataFiltered.push(item);
                }
                return !item.name.includes(name);
            });
            if (arrayDataFiltered.length > 0) {
                setEmployeeDirectoryFiltered(arrayDataFiltered);
            }
            else {
                setEmployeeDirectoryFiltered(EmployeeDirectory);
            }
        }
        else {
            EmployeeDirectory.filter(function (item: EmployeeDirectoryDTO) {
                item.responsabilities.map(r => {
                    if (r.responsabilityName == name) {
                        arrayDataFiltered.push(item);
                    }
                    return !item.name.includes(name);
                })
                if (arrayDataFiltered.length > 0) {
                    setEmployeeDirectoryFiltered(arrayDataFiltered);
                }
                else {
                    setEmployeeDirectoryFiltered(EmployeeDirectory);
                }
            });
        }
    }

    const cleanTag = () => {
        GetEmployeeDirectory();
    }

    const sendEmail = () => {
        window.location.href = `mailto:${selectedUsers.toString().replaceAll(',', ';')}`;
    }

    const handleSelectedUsers = (checked: boolean, email: string) => {
        let users = selectedUsers;
        if (checked) {
            users.push(email);
        }
        else {
            users = users.filter(x => x != email);
        }
        setSelectedUsers(users);
    }

    return {
        EmployeeDirectoryFiltered,
        onSearch,
        loading,
        handleSelectedUsers,
        selectedUsers,
        sendEmail,
        GetResponsabilityAreas,
        responsabilityAreas,
        cleanTag,
    }
}