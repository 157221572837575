import { FileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Divider, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import * as React from 'react';
import { useApplications } from '../../../Hooks/useApplications';
import { EmployeePermissions, Module, subModule } from '../../../Models/EmployeePermissions.model.';
import { IInternalAppsProps } from './IInternalAppsProps';
import './InternalApps.css';


const InternalApps: React.SFC<IInternalAppsProps> = (props) => {

  const { Panel } = Collapse;

  function callback(key: any) {
    console.log(key);
  }
  const { AppsEmployee, goToApp } = useApplications();

  return (
    <div>
      <Row style={{ textAlign: 'center' }} >
        {
          AppsEmployee.map((x: EmployeePermissions) =>
          
            <Col xs={22} sm={22} md={11} lg={11} xl={11} className='containerApp' >
              <div className='ant-card-coverApp'>
                {x.appName}
              </div>

              {x.modules.map((x, i) =>
                <Collapse onChange={callback}>
                  <Panel className='modules' header={x.name} key={i}>
                    {x.subModules.map(s =>
                      <div className='subModules' onClick={() => s.url !== "" ? goToApp(s.url) : null}>&#8226; {s.name}</div>
                    )}
                  </Panel>
                </Collapse>
              )}
            </Col>
          )
        }
      </Row>
    </div>
  );
};

export default InternalApps;
