import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useSuggestions } from "../../Hooks/useSuggestions";
import SuggestionBoxHeader from "./SuggestionBoxHeader";
import "./Suggestions.css";

const SuggestionsBox = () => {
  const { suggestion, sendSuggestion, setSuggestion, loading } =
    useSuggestions();

  return (
    <>
      <SuggestionBoxHeader />
      <div className="subtitle-container">
        <h1>Comparte tus sugerencias</h1>
        <h3>Cuéntanos en qué podemos mejorar. <strong>Esta información se enviará de forma anónima desde un buzón de correo genérico.</strong></h3>
      </div>

      <div className="shadow-container">
        <img
          alt="suggestion"
          className="image"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/suggestion.png"
        />
        <div className="suggestion-box">
          <TextArea
            rows={10}
            className="textArea"
            placeholder="Cuéntanos en qué podemos mejorar"
            value={suggestion}
            name="suggestion"
            onChange={(e) => setSuggestion(e.target.value)}
          />
          <Button
            className="button"
            onClick={() => sendSuggestion(suggestion)}
            disabled={!suggestion}
            loading={loading}
          >
            Enviar
          </Button>
        </div>
      </div>
    </>
  );
};

export default SuggestionsBox;
