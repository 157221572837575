import axios from 'axios';
import { Auth } from '../Models/Auth.model';
const WebAapiURL = process.env.REACT_APP_WebAapiURL;
const LoginAPI = {
    login(data: Auth) {
        return new Promise((resolve, reject) => {
            axios.post(`${WebAapiURL}v1/Login`, data)
                .then(json => resolve(json))
                .catch(err => reject(err));
        });
    },
    SaveToken(loginName:string,token:string) {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Login/SaveToken?loginName=${loginName}&token=${token}`)
                .then(json => resolve(json.data))
                .catch(err => reject(err));
        });
    },
    ValidateToken(token:string) {
        return new Promise((resolve, reject) => {
            axios.get(`${WebAapiURL}v1/Login/ValidateToken?token=${token}`)
                .then(json => resolve(json.data))
                .catch(err => reject(err));
        });
    },
    resetPass(data:any) {
        return new Promise((resolve, reject) => {
            axios.post(`${WebAapiURL}v1/Login/resetPass`,data)
                .then(json => resolve(json.data))
                .catch(err => reject(err));
        });
    },
}

export default LoginAPI;