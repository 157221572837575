import * as React from 'react';
import { IDaysSummaryProps } from './IDaysSummaryProps';
import './DaysSummary.css';
import { Divider } from 'antd';
import { useState } from 'react';

const DaysSummary: React.SFC<IDaysSummaryProps> = (props) => {

    return (
        <div className='contenedor'>
            <div className='header'>
                {props.title}
            </div>
        <div className='divider'>

        </div>
            <div className='days'>
                {props.days}
            </div>
        </div>
    );
};

export default DaysSummary;