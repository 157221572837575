import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { ICalendarProps } from './ICalendarProps';
import { useVacation } from '../../../Hooks/useVacation';
import { useEffect } from 'react';

const localizer = momentLocalizer(moment)

const VacationCalendar: React.SFC<ICalendarProps> = (props) => {

  const {
    calendarLIstEventsArea,
    calendarListEvents,
    GetVacationRequestList,
    GetVacationTeamRequestList
  } = useVacation(); 

  useEffect(() => {
    GetVacationRequestList();
    GetVacationTeamRequestList();
 }, []);

  return (
    <div>
      <div style={{textAlign:'center', fontSize:'x-large', fontWeight:'bold', color:'rgb(72, 95, 115)'}}>
        {props.type == "user" ? "Mi calendario" : "Calendario área"}
      </div><br/>
      <Calendar
        localizer={localizer}
        events={props.type == "user" ? calendarListEvents : calendarLIstEventsArea}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  )
}

export default VacationCalendar;