import * as React from 'react';
import { IFooterProps } from './IFooterProps';
import './Footer.css';
import { Col, Row } from 'antd';

const Footer: React.SFC<IFooterProps> = (props) => {
  return (
    <div className={`footer`}>
      <Row className="contenedorFooter">
        <Col xs={24} md={24} lg={24}>
          Copyright © 2022 Qualitas
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
